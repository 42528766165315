import React from 'react';
import MainTemplate from 'components/MainTemplate/MainTemplate';
import ServiceIntroduce from 'components/ServiceIntroduce/ServiceIntroduce.js';

const ServiceIntroducePage = () => {
  return (
    // <MainTemplate pageType={'service_intro'}>
    <ServiceIntroduce />
    // </MainTemplate>
  );
};

export default ServiceIntroducePage;
