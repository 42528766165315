import React from 'react';
import BindSiteContainer from 'containers/ServiceNotice/BindSiteNotice';

const BindSite = () => {
  return (
    <BindSiteContainer/>
  );
};

export default BindSite;
