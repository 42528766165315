import React from 'react';
import AndroidNoticeContainer from 'containers/ServiceNotice/AndroidNotice';

const AndroidNotice = () => {
  return (
    <AndroidNoticeContainer/>
  );
};

export default AndroidNotice;
