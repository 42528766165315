import React from 'react';
import AndroidStNoticeContainer from 'containers/ServiceNotice/AndroidStNotice';

const AndroidStNotice = () => {
  return (
    <AndroidStNoticeContainer/>
  );
};

export default AndroidStNotice;
