import React from 'react';
import WebStNoticeContainer from 'containers/ServiceNotice/WebStNotice';

const WebStNotice = () => {
  return (
    <WebStNoticeContainer/>
  );
};

export default WebStNotice;
