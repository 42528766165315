import React from 'react';
import NotFound from 'components/NotFound';

const NotFoundPage = () => {
  return (
    <NotFound />
  );
};

export default NotFoundPage;
